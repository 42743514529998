// This intermediate file is used for treeshaking
// It allows us to do "import * as Icons" and to then
// access the icons as <Icons[icon] /> without adding all
// possible icons to the bundle.
// https://react-icons.netlify.com/#/
export {
  FaUsers,
  FaRegHandPeace,
  FaPeopleCarry,
  FaBook,
  FaBox,
  FaRegListAlt,
  FaShippingFast,
} from "react-icons/fa"

export { GoMegaphone, GoChecklist } from "react-icons/go"

export {
  AiOutlineUsergroupAdd,
  AiOutlineBook,
  AiOutlineDotChart,
} from "react-icons/ai"

export {
  FiActivity,
  FiBook,
  FiLayout,
  FiUsers,
  FiCompass,
  FiSunrise,
  FiCheckCircle,
} from "react-icons/fi"

export {
  IoIosPeople,
  IoIosPaper,
  IoMdPlay,
  IoMdTrendingUp,
  IoIosMegaphone,
  IoIosImages,
} from "react-icons/io"

export { BsTools, BsCheckCircle } from "react-icons/bs"

export { RiPagesLine } from "react-icons/ri"
